export class ReserveGoShare extends HTMLElement {
  unknownBadge: HTMLElement;
  availableBadge: HTMLElement;
  unavailableBadge: HTMLElement;
  checkBtn: HTMLElement;
  hiddenUsernameInput: HTMLInputElement;
  currentStep: number;

  constructor() {
    super();
    this.currentStep = 1;
  }

  connectedCallback(): void {
    this.addEventListeners();
    this.showBadge("unknown");
    this.updateStep();

    // Check if hidden username field has a value on page load
    const hiddenValue = this.hiddenUsernameInput?.value;
    if (hiddenValue) {
      const input = this.querySelector(
        'input[name="username"]'
      ) as HTMLInputElement;
      input.value = hiddenValue;
    }
  }

  addEventListeners(): void {
    const form = this.querySelector("form") as HTMLFormElement;
    // form.addEventListener("submit", this.handleFormSubmit.bind(this));

    const input = this.querySelector(
      'input[name="username"]'
    ) as HTMLInputElement;
    if (input) {
      input.addEventListener("input", this.formatInput.bind(this));
      input.addEventListener("focus", this.selectUsername.bind(this));
      input.addEventListener("blur", () => this.validateUsername(input));
    }

    this.unknownBadge = this.querySelector(".unknown-badge") as HTMLElement;
    this.availableBadge = this.querySelector(".available-badge") as HTMLElement;
    this.unavailableBadge = this.querySelector(
      ".unavailable-badge"
    ) as HTMLElement;
    this.checkBtn = this.querySelector(".check-btn") as HTMLElement;
    this.hiddenUsernameInput = document.querySelector(
      "input[name='username'][type='hidden']"
    ) as HTMLInputElement;
  }

  handleFormSubmit(event: Event): void {
    event.preventDefault();

    const form = event.currentTarget as HTMLFormElement;

    if (this.currentStep === 1) {
      // Validate username before proceeding
      const input = this.querySelector(
        'input[name="username"]'
      ) as HTMLInputElement;
      this.validateUsername(input);
    } else if (this.currentStep === 2) {
      // Check if the email input is valid before submitting
      const emailInput = this.querySelector(
        'input[name="email"]'
      ) as HTMLInputElement;

      if (emailInput && emailInput.checkValidity()) {
        // All validations are done, submit the form
        // If HTMX is handling the form submission
        const htmx = (window as any).htmx;
        if (htmx) {
          htmx.trigger(form, "submit");
        }
        // form.submit();
      } else {
        // Focus the email input if it's not valid
        emailInput.focus();
      }
    }
  }

  updateStep(): void {
    const steps = this.querySelectorAll(".step-1, .step-2");

    steps.forEach((step, index) => {
      // Make sure to show the current step and all previous steps
      if (index + 1 <= this.currentStep) {
        step.classList.remove("hidden");
      } else {
        step.classList.add("hidden");
      }
    });

    // const button = this.querySelector("button") as HTMLButtonElement;
    // button.textContent =
    //   this.currentStep < steps.length ? "Next" : "Reserve Your GoShare URL";
  }

  showBadge(badge: "unknown" | "available" | "unavailable"): void {
    if (this.unknownBadge) {
      this.unknownBadge.style.display = badge === "unknown" ? "inline" : "none";
    }
    if (this.availableBadge) {
      this.availableBadge.style.display =
        badge === "available" ? "inline" : "none";
    }
    if (this.unavailableBadge) {
      this.unavailableBadge.style.display =
        badge === "unavailable" ? "inline" : "none";
    }
  }

  formatInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Enforce lowercase, remove invalid characters (only allow lowercase letters, numbers, and underscores)
    value = value.toLowerCase().replace(/[^a-z0-9_]/g, "");

    // Prevent "goshare" as a substring
    if (value.includes("goshare")) {
      value = value.replace(/goshare/g, "");
    }

    // Update the input value
    input.value = value;

    this.showBadge("unknown");
  }

  selectUsername(event: FocusEvent): void {
    const input = event.target as HTMLInputElement;
    input.setSelectionRange(0, input.value.length);
  }

  validateUsername(input: HTMLInputElement): void {
    const username = input.value;

    if (username) {
      this.checkUsernameAvailability(username)
        .then((isAvailable) => {
          if (!isAvailable) {
            input.setCustomValidity("This username is already taken.");
            this.showBadge("unavailable");
            input.reportValidity();
          } else {
            input.setCustomValidity("");
            this.showBadge("available");
            // Populate hidden username field on success
            if (this.hiddenUsernameInput) {
              this.hiddenUsernameInput.value = username;
            }
            // Move to the next step after successful validation
            this.currentStep++;
            this.updateStep();
          }
        })
        .catch((error) => {
          console.error("Error validating username:", error);
          input.setCustomValidity(
            "There was an error validating the username. Please try again."
          );
          input.reportValidity();
          this.showBadge("unknown");
        });
    } else {
      this.showBadge("unknown");
    }
  }

  async checkUsernameAvailability(username: string): Promise<boolean> {
    try {
      const response = await fetch("/api/check-username", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username }),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data.available;
    } catch (error) {
      console.error("Error checking username availability:", error);
      return false;
    }
  }
}

customElements.define("reserve-goshare", ReserveGoShare);
